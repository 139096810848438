





































































import { patch_device, post_device } from "@/api";
import subDomain from "@/shared/init";
import { VuetifyForm } from "@/shared/types";
import { required } from "@/shared/validation";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

const DevLocalSearch = () => import("@/components/devLocalSearch.vue");

type formType = {
  uuid: string;
  name: string;
  ipAddress: string;
  ipPublic: string;
  openedPort: number;
  deviceLocation: string;
  note: string;
};
@Component({ name: "device-edit", components: { DevLocalSearch } })
export default class DeviceEdit extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value: formType | null = null;

  /* Variables */

  isIwkKlang = subDomain === "iwk-klang.";

  required = required;

  edit = false;

  formData = {
    uuid: null as unknown as string,
    name: null as unknown as string,
    ipAddress: null as unknown as string,
    ipPublic: null as unknown as string,
    openedPort: null as unknown as number,
    deviceLocation: null as unknown as string,
    note: null as unknown as string,
  };

  /* Computed */
  /* Methods */

  @Emit("submit")
  async submit(): Promise<void> {
    try {
      if (this.edit) {
        await patch_device(this.formData);
        Notif.notif_success("Device Successfully Editted !!");
      } else {
        await post_device(this.formData);
        Notif.notif_success("Device Successfully Added !!");
      }
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.close();
    }
  }

  @Emit("input")
  close(): null {
    (this.$refs.formData as VuetifyForm).reset();
    this.edit = false;
    return null;
  }

  /* Life-cycle Methods */

  @Watch("value")
  onValueChange(now: formType | boolean): void {
    if (now == true) return;
    if (now) {
      this.formData = now;
      this.edit = true;
    }
  }
}
